<template>
  <div class="weChat-container" v-show="isCN">
    <el-popover
        placement="left"
        width="230"
        trigger="hover">
      <div class="popover-wrapper">
        <img src="@/assets/weChat/weChat3.png" alt="">
        <p>{{ $t('User.WeChat.Tips1') }}</p>
        <p>{{ $t('User.WeChat.Tips2') }}</p>
        <p>{{ $t('User.WeChat.Tips3') }}</p>
      </div>
      <div class="img-wrapper" slot="reference">
        <img src="@/assets/weChat/WeChat1.png" alt="" class="default-img">
        <img src="@/assets/weChat/WeChat2.png" alt="" class="hover-img">
      </div>
    </el-popover>
  </div>
</template>

<script>
import { cnSite } from "@/utils";

export default {
  name: "weChat",
  computed: {
    isCN() {
      return cnSite() || process.env.VUE_APP_NODE_TEST === 'development';
    },
  },

};
</script>

<style scoped lang="scss">
.weChat-container {
  position: fixed;
  bottom: 120px;
  right: 20px;
  display: flex;
  z-index: 99;
  .img-wrapper {
    display: flex;
    width: 40px;
    height: 40px;
    position: relative;
    img {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      &.default-img {
        opacity: 1;
      }
      &.hover-img {
        opacity: 0;
      }
    }
    &:hover {
      .default-img {
        opacity: 0;
      }
      .hover-img {
        opacity: 1;
      }
    }
  }
}
.popover-wrapper {
  text-align: center;
  p {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }
}
</style>