import { jsPDF } from 'jspdf';
import 'svg2pdf.js';
// pdfmake
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
// 初始化字体
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import axios from "axios";
import server from "@/server/request";
import {URL_PREFIX} from "@/const/const";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

export function download(url, newTab) {
    const a = document.createElement("a");
    if (newTab) {
        a.target = "_blank";
    }

    const event = new MouseEvent("click");
    a.href = url;
    a.dispatchEvent(event);
}

/**
 * 字符串转csv文件
 * 下载csv文件
 * @param {*} str
 */
export function downloadStringToCsvFile(str, name) {
    const blob = new Blob([str]);
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, name);
    } else {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    }
}

export function downloadFile(url, name = '下载', message) {
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", name);
    a.setAttribute("target", "_blank");
    const clickEvent = document.createEvent("MouseEvents");
    clickEvent.initEvent("click", true, true);
    a.dispatchEvent(clickEvent);
    // !!message && message.close();
    setTimeout(() => {
        if (message) {
            message.close();
        }
    }, 800);
}
export async function downloadFileSave(url, name = '下载', message) {

    saveAs(url, name);
    setTimeout(() => {
        if (message) {
            message.close();
        }
    }, 800);
}

export function downloadSvg2Pdf(imageBase64, svg, outFileName = 'newplot.pdf', message, isjsPdf = false, isZip) {
    if(isjsPdf) {
        const format = isZip ? 'pdf' : '';
        downloadSvg2PdfByJsPdf(imageBase64, svg, outFileName, message, format);
        return;
    }
    const img = new Image(); // 创建图片容器
    img.src = imageBase64; //imageBase64 为svg+xml的Base64 文件流
    const svgContent = typeof svg === 'string' ? svg : svg.outerHTML;
    img.onload = async() => {
        // const size = [img.width, img.height];
        const docDefinition = {
            pageSize: {
                width: img.width + 50,
                height: img.height + 50,
            },
            compress: false,
            // pageMargins: [ 40, 60, 40, 60 ],
            margin: [50, 50],
            content: [
                {
                    // svg: svgContent, // 使用SVG字符串
                    svg: svgContent,
                    alignment: 'center',
                },
            ],
            defaultStyle: {
                backgroundColor: '#ffffff', // 设置默认背景色为白色
            },
        };
        // 生成并下载PDF
        pdfMake.createPdf(docDefinition).download(outFileName);
        setTimeout(() => {
            if (message) {
                message.close();
            }
        }, 2000);
    };
}

export function downloadSvg2PdfByJsPdf(imageBase64, svg1, outFileName = 'newplot.pdf', message) {
    const svg = typeof svg1 === 'string' ? stringToHTML(svg1) : svg1;
    const img = new Image(); // 创建图片容器
    img.src = imageBase64; //imageBase64 为svg+xml的Base64 文件流
    // svg = stringToHTML(svg);
    svg.querySelectorAll('text').forEach(ele => {
        // ele.setAttribute('fill', 'transparent');
        if(ele.hasAttribute('textLength')) {
            ele.setAttribute('textLength', '');
        }
    });
    img.onload = async() => {
        const size = [img.width, img.height];
        const pdf = new jsPDF(img.width > img.height ? 'l'  : 'p', 'pt', size);
        // const areaSvg = parseToDomObject(svg);
        await pdf.svg(svg);
        pdf.save(outFileName);
        // const uri = pdf.output('datauristring');
        // saveAs(uri, outFileName);
        setTimeout(() => {
            if (message) {
                message.close();
            }
        }, 4000);
    };
}

function stringToHTML(str) {
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(str, 'text/html');
    // return doc.body.children[0];
    const parser = new DOMParser();
    // 将SVG字符串解析为Document对象
    const doc = parser.parseFromString(str, 'image/svg+xml');
    // 从Document中提取SVG元素
    return  doc.documentElement;
    // console.log('2222222', svgElement);
}

export async function svgBase64ToOtherType(imageBase64, suffix, name, message, isjsPdf) {
    // const format1 = isZip ? suffix : '';
    if (suffix === 'tiff') {
        server.svg2tiff(URL_PREFIX + '/user/files/svg2tiff_64', imageBase64, () => {})
            .then(response => {
                const newImageBase64 = response.data;
                // console.log('打印查看响应', newImageBase64);
                const myUrl = URL.createObjectURL(newImageBase64);
                // downloadFile(myUrl, name + '.tiff', message);
                downloadFileSave(myUrl, name + '.tiff', message);
            });
        return;
    }

    if (suffix === 'pdf') {
        const svg = atob(imageBase64.replace(/data:image\/svg\+xml;base64,/, ''));
        downloadSvg2Pdf(imageBase64, svg, `${name}.pdf`, message, isjsPdf);
        return;
    }
    // saveAs(imageBase64, name + '.' + suffix);
    const img = new Image(); // 创建图片容器
    img.src = imageBase64; //imageBase64 为svg+xml的Base64 文件流
    img.onload = () => {
        // 图片创建后再执行，转Base64过程
        const canvas = document.createElement('canvas');
        canvas.width = img.width; //设置好 宽高  不然图片 不完整
        canvas.height = img.height;
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0);
        const type = 'image/' + suffix;
        const ImgBase64 = canvas.toDataURL(type);
        // downloadFile(ImgBase64, name, message);
        downloadFileSave(ImgBase64, `${name}.${suffix}`, message);
    };
}

export function downloadSvgToFormat(svgUrl, suffix, name, message, isjsPdf = false) {
    if (suffix === 'svg') {
        downloadFileSave(svgUrl, `${name}.${suffix}`, message);
    } else if (suffix) {
        svgBase64ToOtherType(svgUrl, suffix, name, message, isjsPdf);
    }
}

export function saveImagesTo(dataUrl, name, message) {
    const alink = document.createElement('a');
    alink.href = dataUrl;
    // console.log('打印查看screeshot', _this.screenshot)
    alink.download = name;
    alink.click();
    if (message === undefined) {return false;}
    setTimeout(() => {
        if (message) {
            message.close();
        }
    }, 800);
}

export function download_file(url, name) {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.style.height = 0;
    iframe.src = url;
    if(name) {
        iframe.name = name;
    }
    document.body.appendChild(iframe);
    setTimeout(() => {
        iframe.remove();
    }, 5 * 60 * 1000);
}

function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
            reject(new Error('blobToBase64 error'));
        };
    });
}
export function downloadSvgToBlob(svgUrl, suffix, name, message, isJspdf = false) {
    const arr1 = svgUrl.split('?');
    const arr2 = decodeURIComponent(arr1[1]);
    const newUrl = [arr1[0], arr2].join('?');
    axios({
        url: newUrl,
        method: "GET",
        responseType: 'blob',
        withCredentials: false,
    }).then((res) => {
        blobToBase64(res.data).then(res1 => {
            // console.log('1233333333', res1);
            if (suffix === 'svg') {
                downloadFile(res1, name, message);
            } else if (suffix) {
                svgBase64ToOtherType(res1, suffix, name, message, isJspdf);
            }
        });
    });
}


export function downloadFromS3(s3Url, newFilename) {
    const arr1 = s3Url.split('?');
    const arr2 = decodeURIComponent(arr1[1]);
    const newUrl = [arr1[0], arr2].join('?');
    const names = arr1[0].split('/');
    let suffix = '';
    if(names.length) {
        const fileName = [...names].pop();
        const arr = fileName.split('.');
        const removeFirst = arr.length ? arr.slice(1) : '';
        suffix = removeFirst.join('.');
    }
    axios({
        url: newUrl,
        method: "GET",
        responseType: 'blob',
        withCredentials: false,
    }).then((res) => {
        // 创建隐藏的可下载链接
        const url = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = url;
        link.style.display = 'none';
        if(newFilename) {
            link.download = newFilename + '.' + suffix;
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
}
export function base64ToPdfBlob(base64Pdf) {
    const binaryString = window.atob(base64Pdf); // 移除Base64前缀并解码
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/pdf' });
}
export function base64ToBlob(base64String, type) {
    // Base64解码为ArrayBuffer
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    // 合并ArrayBuffers为单个ArrayBuffer
    const format = type !== 'pdf' ? `image/${type}` : '';
    return new Blob(byteArrays, {type: format});
}
export async function downloadBase64Zip(base64String, fileName, type) {
    let imageBlob;
    if(type === 'pdf') {
        imageBlob = base64ToPdfBlob(base64String);
    } else if(type !== 'tiff') {
        imageBlob = base64ToBlob(base64String, type);
    } else {
        imageBlob = base64String;
    }
    // 使用JSZip创建压缩包
    const zip = new JSZip();
    const filename1 = fileName.split('.')[0];
    const imgName = filename1 + '.' + type; // 图片在压缩包中的文件名
    zip.file(imgName, imageBlob, {binary: true});
    // 生成Zip文件的Blob并下载
    const zipBlob = await zip.generateAsync({type: 'blob'});
    saveAs(zipBlob, filename1);
}



export async function downloadBlobsAsZip(res) {
    // 假设blobs是一个Blob对象数组
    const blobs = [res];
    // 初始化JSZip实例
    const zip = new JSZip();

    // 遍历Blob对象，添加进zip
    blobs.forEach((blob, index) => {
        // 为每个Blob指定一个文件名，例如"file1.txt", "file2.jpg"等
        const fileName = `file${index + 1}.${blob.type.split('/')[1]}.csv`;
        zip.file(fileName, blob, {binary: true});
    });
    // 生成最终的zip文件Blob
    const zipBlob = await zip.generateAsync({type: "blob"});

    // 创建隐藏的a标签来下载
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(zipBlob);
    // link.download = "archive.zip";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // URL.revokeObjectURL(link.href);
    saveAs(zipBlob, "archive.zip");
}