<template>
  <div class="tutorials-container" v-if="token">
    <div class="img-wrapper">
      <img src="@/assets/tutorials/tutorials.png" alt="" class="default-img">
      <img src="@/assets/tutorials/tutorials2.png" alt="" class="hover-img" :class="{'flash': firstEnter}" @click="showTutorials">
    </div>
    <el-dialog
        :width="dialogWidth"
        :visible.sync="tutorialsVisible"
        :show-close="false"
        append-to-body
        :top="dialogTop"
        custom-class="tutorialsDialog"
        :close-on-click-modal="false">
      <step-one-comp v-if="currentStep === 'one'" @closeDialog="closeDialog" @sendNextStep="getNextStep" />
      <basic-layout
          v-else
          ref="basicRouterRef"
          @closeDialog="closeDialog"
          @sendShowCurrentInfo="getNextStep"
          @sendSeeMore="getSeeMore">
        <template slot="content">
          <step-two-comp v-show="currentStep === 'two'" />
          <step-three-comp v-show="currentStep === 'three'" />
          <step-four-comp v-show="currentStep === 'four'" />
        </template>
      </basic-layout>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import stepOneComp from "@/components/Tutorials/component/stepOne";
import stepTwoComp from "@/components/Tutorials/component/stepTwo";
import stepThreeComp from "@/components/Tutorials/component/stepThree";
import stepFourComp from "@/components/Tutorials/component/stepFour";
import {Local} from "@/utils/storage";
import basicLayout from "@/components/Tutorials/component/basicLayout";
import { routerGuidebook } from "@/const/myAnalyses";

export default {
  name: "TutorialsComp",
  components: {
    stepOneComp,
    stepTwoComp,
    stepThreeComp,
    stepFourComp,
    basicLayout,
  },
  data() {
    return {
      tutorialsVisible: false,
      currentStep: 'one', // 用户刚进来，展示 步骤一，点击灯泡，步骤二开始
      firstEnter: false,
    };
  },
  watch: {
    $route(to,from) {
      this.setOpenedStatus(to, from);
    },
  },

  computed: {
    ...mapGetters(['token']),
    dialogWidth() {
      return this.currentStep === 'one' ? '560px' : '1000px';
    },
    dialogTop() {
      return this.currentStep === 'one' ? '30vh' : '15vh';
    },
  },
  methods: {
    // 用户登陆展示教程，从其他页面来到 My analyses不展示
    setOpenedStatus(to, from) {
      if(!this.token) {return;}
      this.firstEnter = false;
      this.tutorialsVisible = false;
      const { name } = to;
      if(name === 'MyAnalyzes') {
        // 由于目前后端先不介入，临时使用
        this.currentStep = 'one';
        this.tutorialsVisible = !Local.get('userTutorials');
        this.firstEnter = !Local.get('userTutorials');
      }
      if(name === 'production' && from?.name === 'login') {
        return;
      }
      if(Local.get('userTutorials')) {return;}
      setTimeout(() => {
        this.$store.commit('user/setUserTutorials', true);
      });
    },
    // 点击灯泡
    showTutorials() {
      this.currentStep = 'two';
      this.tutorialsVisible = true;
      this.$nextTick(() => {
        this.$refs.basicRouterRef.resetRouter();
      });
    },
    // 跳过教程
    closeDialog() {
      this.tutorialsVisible = false;
    },
    // 获取当前教程步骤
    getNextStep(step) {
      this.currentStep = step;
    },

    // 了解更多
    getSeeMore(id) {
      this.tutorialsVisible = false;
      this.$router.push({
        path: '/guidebook',
        query: { id: routerGuidebook[id] },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tutorials-container {
  position: fixed;
  bottom: 180px;
  right: 20px;
  display: flex;
  z-index: 99;
  .img-wrapper {
    display: flex;
    width: 40px;
    height: 40px;
    position: relative;
    img {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      &.default-img {
        opacity: 1;
      }
      &.hover-img {
        opacity: 0;
        &.flash {
          animation: flash 1s ease-in-out;
        }
      }
    }
    &:hover {
      .default-img {
        opacity: 0;
      }
      .hover-img {
        opacity: 1;
      }
    }
  }
}

::v-deep .tutorialsDialog {
  border-radius: 8px;
}
.tutorialsDialog ::v-deep .el-dialog {
  margin: 0 auto;
}
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__body {
  //padding: 10px;
  padding: 0;
}




@keyframes flash {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>